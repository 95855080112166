.float-label {
    position: relative;
    margin-bottom: 12px;
}
  
.label {
    color: #757575;
    font-family: Rubik;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 22px;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 14px;
    transition: 0.2s ease all;

}


.label-float {
    top: 6px;
    font-size: 11px!important;
}
  
